import {
  FETCH_TEAM_VACATIONS_START,
  FETCH_TEAM_VACATIONS_SUCCESS,
  FETCH_TEAM_VACATIONS_ERROR,
  FETCH_TEAM_VACATIONS_FOR_AUTO_START,
  FETCH_TEAM_VACATIONS_FOR_AUTO_SUCCESS,
  FETCH_TEAM_VACATIONS_FOR_AUTO_ERROR,
} from './types';
import { getData, baseURL } from './api';
import { setGeneralAlertMessage } from '../actions/uiActions';

export const fetchTeamVacations = params => dispatch => {
  dispatch({ type: FETCH_TEAM_VACATIONS_START });

  if (params.search && params.search === '') delete params.search;

  return getData(
    `${baseURL}api/vacations/team`,
    params,
    FETCH_TEAM_VACATIONS_SUCCESS,
    FETCH_TEAM_VACATIONS_ERROR,
    dispatch,
  );
};

export const fetchVacationForApproval = (vacationId, successCallback) => dispatch => {
  dispatch({ type: FETCH_TEAM_VACATIONS_FOR_AUTO_START });

  getData(
    `${baseURL}api/vacations/team`,
    { status: 'pending' },
    FETCH_TEAM_VACATIONS_FOR_AUTO_SUCCESS,
    FETCH_TEAM_VACATIONS_FOR_AUTO_ERROR,
    dispatch,
    data => {
      const item = data.list.find(vacation => vacation.id === parseInt(vacationId, 10));
      if (item) {
        if (item.status === 'Pending') {
          successCallback(item);
        } else {
          dispatch(
            setGeneralAlertMessage(
              `${item.name}'s vacation has been already ${item.status.toLowerCase()} by ${
                item.approvedBy
              }`,
              'danger',
            ),
          );
          successCallback(null);
        }
      } else {
        dispatch(setGeneralAlertMessage('Could not find vacation', 'danger'));
        successCallback(null);
      }
    },
    error => {
      successCallback(null);
      dispatch(setGeneralAlertMessage(error.response.data.message, 'danger'));
    },
  );
};
