import {
  FETCH_TEAM_VACATIONS_START,
  FETCH_TEAM_VACATIONS_SUCCESS,
  FETCH_TEAM_VACATIONS_ERROR,
  FETCH_TEAM_VACATIONS_FOR_AUTO_START,
  FETCH_TEAM_VACATIONS_FOR_AUTO_SUCCESS,
  FETCH_TEAM_VACATIONS_FOR_AUTO_ERROR,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  list: [],
  isFetchingForAutoApproval: false,
  vacationsForAutoApproval: [],
  listPage: 1,
  listTotal: 1,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_TEAM_VACATIONS_START:
      return { ...state, isFetching: true };
    case FETCH_TEAM_VACATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        listPage: action.payload.page,
        listTotal: action.payload.total,
        isFetching: false,
      };
    case FETCH_TEAM_VACATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_TEAM_VACATIONS_FOR_AUTO_START:
      return { ...state, isFetchingForAutoApproval: true };
    case FETCH_TEAM_VACATIONS_FOR_AUTO_SUCCESS:
      return {
        ...state,
        vacationForAutoApproval: action.payload,
        isFetchingForAutoApproval: false,
      };
    case FETCH_TEAM_VACATIONS_FOR_AUTO_ERROR:
      return { ...state, isFetchingForAutoApproval: false };
    default:
      return state;
  }
};
