import {
  FETCH_DASHBOARD_VACATIONS_START,
  FETCH_DASHBOARD_VACATIONS_SUCCESS,
  FETCH_DASHBOARD_VACATIONS_ERROR,
  FETCH_DASHBOARD_ANNIVERSARIES_START,
  FETCH_DASHBOARD_ANNIVERSARIES_SUCCESS,
  FETCH_DASHBOARD_ANNIVERSARIES_ERROR,
  FETCH_DASHBOARD_WFH_START,
  FETCH_DASHBOARD_WFH_SUCCESS,
  FETCH_DASHBOARD_WFH_ERROR,
} from './types';
import { getData, baseURL } from './api';
import { formatDate } from '../../utils/Date';

export const fetchDashboardVacations = (
  startDate,
  endDate,
  vacationTypeId,
  studioId,
  teamId,
) => dispatch => {
  startDate.setDate(startDate.getDate() - 6);
  endDate.setDate(endDate.getDate() + 6);
  dispatch({ type: FETCH_DASHBOARD_VACATIONS_START });
  const start = formatDate(startDate, 'YYYY-MM-DD');
  const end = formatDate(endDate, 'YYYY-MM-DD');
  return getData(
    `${baseURL}api/vacations/${start}/${end}/${studioId}/${vacationTypeId}/${teamId}`,
    {},
    FETCH_DASHBOARD_VACATIONS_SUCCESS,
    FETCH_DASHBOARD_VACATIONS_ERROR,
    dispatch,
  );
};

export const fetchDashboardAnniversaries = (startDate, endDate, studioId, teamId) => dispatch => {
  startDate.setDate(startDate.getDate() - 6);
  endDate.setDate(endDate.getDate() + 6);
  dispatch({ type: FETCH_DASHBOARD_ANNIVERSARIES_START });
  const start = formatDate(startDate, 'YYYY-MM-DD');
  const end = formatDate(endDate, 'YYYY-MM-DD');
  return getData(
    `${baseURL}api/anniversaries/${start}/${end}/${studioId}/${teamId}`,
    {},
    FETCH_DASHBOARD_ANNIVERSARIES_SUCCESS,
    FETCH_DASHBOARD_ANNIVERSARIES_ERROR,
    dispatch,
  );
};

export const fetchDashboardWFH = selectedStudio => dispatch => {
  const workingLocations = {
    FROM_HOME: 1,
    FROM_OFFICE: 0,
  };

  const studioId = selectedStudio ? selectedStudio.value : 0;

  dispatch({ type: FETCH_DASHBOARD_WFH_START });

  return getData(
    `${baseURL}api/working-location/${workingLocations.FROM_HOME}/${studioId}`,
    {},
    FETCH_DASHBOARD_WFH_SUCCESS,
    FETCH_DASHBOARD_WFH_ERROR,
    dispatch,
  );
};
