import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/ui/Header';
import DeleteAcceptVacationRequestModal from '../../components/modals/DeleteAcceptVacationRequestModal';
import RejectVacationRequestModal from '../../components/modals/RejectVacationRequestModal';
import VacationRequestsList from './components/VacationRequestsList';
import {
  fetchTeamVacations,
  fetchVacationForApproval,
} from '../../redux/actions/teamVacationsActions';
import {
  deleteRequest,
  requestAnswer,
  fetchRequest,
  resetRequestFlow,
} from '../../redux/actions/signedInUserVacationsActions';
import { resetRejectVacationRequestModalForm } from '../../redux/actions/uiActions';
import {
  approveWorkOnHoliday,
  deleteWorkOnHoliday,
  rejectWorkOnHoliday,
} from '../../redux/actions/publicHolidaysActions';

class VacationRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: props.teamVacationsPage || 1,
      currentTab: 'pending',
      showConfirmModal: false,
      showRejectVacationRequestModal: false,
      vacationId: 0,
      onSubmitConfirmModal: () => this.onConfirmDelete,
      confirmModalHeader: 'Delete vacation request',
      currentVacation: {},
      clearSearch: false,
      searchValue: '',
    };

    this.handleCurrentTabChange = this.handleCurrentTabChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleEmailLink();
  }

  handleEmailLink = async () => {
    try {
      const { location, fetchVacationForApproval, fetchRequest, fetchTeamVacations } = this.props;
      const { currentTab } = this.state;

      // extract URL params
      const params = new URLSearchParams(location.search);
      const urlParams = {
        vacationId: params.get('vacationId'),
        holidayWorkId: params.get('holiday_work_request_id'),
        action: params.get('action'),
      };

      // handle cases where no IDs are present - fetch team vacations
      if (!urlParams.holidayWorkId && !urlParams.vacationId) {
        return fetchTeamVacations({ status: currentTab });
      }

      // action handlers
      const actionHandlers = {
        reject: data => {
          this.setState({ currentVacation: data }, this.toggleRejectVacationRequestModal);
        },
        approve: data => {
          this.setState({ currentVacation: data }, () =>
            this.onApprove({
              id: urlParams.vacationId || urlParams.holidayWorkId,
              name: data.name,
              vacationTypeId: data.vacationTypeId,
            }),
          );
        },
      };

      // fetch and process vacation data
      const fetchAndProcessVacation = async () => {
        const fetchFunction = urlParams.holidayWorkId ? fetchVacationForApproval : fetchRequest;
        const id = urlParams.holidayWorkId || urlParams.vacationId;

        return new Promise(resolve => {
          fetchFunction(id, data => resolve(data));
        });
      };

      const vacationData = await fetchAndProcessVacation();

      if (!vacationData) {
        return this.cleanParams();
      }

      const actionHandler = actionHandlers[urlParams.action];
      if (actionHandler) {
        actionHandler(vacationData);
      }
    } catch (error) {
      console.error('Error processing email link:', error);
      this.cleanParams();
    }
  };

  handleCurrentTabChange(type) {
    this.setState({
      ...this.state,
      currentTab: type,
      activePage: 1,
      clearSearch: true,
      searchValue: '',
    });

    this.props.fetchTeamVacations({ status: type });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.props.fetchTeamVacations({
      status: this.state.currentTab,
      search: this.state.searchValue,
      page: pageNumber,
    });
  }

  onDelete = data => {
    this.setState(
      {
        vacationId: data.id,
        onSubmitConfirmModal: () => this.onConfirmDelete(),
        confirmModalHeader: 'Delete request',
        currentVacation: data,
      },
      () => {
        this.toggleConfirmModal();
      },
    );
  };

  toggleConfirmModal = () => {
    this.setState({
      showConfirmModal: !this.state.showConfirmModal,
    });
  };

  toggleRejectVacationRequestModal = () => {
    this.props.resetRejectVacationRequestModalForm();
    //on modal close, clean query params
    this.state.showRejectVacationRequestModal && this.cleanParams();
    this.setState({
      showRejectVacationRequestModal: !this.state.showRejectVacationRequestModal,
    });
  };

  onConfirmDelete = () => {
    this.toggleConfirmModal();
    const { currentVacation } = this.state;

    if (currentVacation.vacationTypeId === '-2') {
      this.props.deleteWorkOnHoliday(
        this.state.vacationId,
        'approvedRequests',
        this.state.currentTab,
        currentVacation.name,
      );
    } else {
      this.props.deleteRequest(this.state.vacationId, 'approvedRequests', this.state.currentTab);
    }
    this.setState({ activePage: 1 });
  };

  onApprove = data => {
    if (data.vacationTypeId === '-2') {
      this.props.approveWorkOnHoliday(data.id, data.name, this.state.currentTab);
    } else {
      this.props.requestAnswer(data.id, 'approve', {}, this.state.currentTab, data.name);
    }
    this.setState({ activePage: 1 });
    if (this.state.showConfirmModal) {
      this.toggleConfirmModal();
    }
    //clean query parameters
    this.cleanParams();
  };

  cleanParams() {
    this.props.history.push(this.props.history.location.pathname);
    this.props.resetRequestFlow();
  }

  onDeny = data => {
    this.toggleRejectVacationRequestModal();
    this.setState({
      currentVacation: data,
    });
  };

  onConfirmDeny = data => {
    this.toggleRejectVacationRequestModal();
    const { currentVacation } = this.state;

    if (currentVacation.vacationTypeId === '-2') {
      this.props.rejectWorkOnHoliday(
        currentVacation.id,
        currentVacation.name,
        this.state.currentTab,
        { reason: data.reason },
      );
    } else {
      this.props.requestAnswer(
        currentVacation.id,
        'reject',
        { reason: data.reason },
        this.state.currentTab,
        currentVacation.name,
      );
    }
    this.setState({ activePage: 1 });
  };

  handleSearchData = searchData => {
    this.setState({
      searchValue: searchData,
      activePage: 1,
    });
    this.props.fetchTeamVacations({
      status: this.state.currentTab,
      search: searchData,
      page: 1,
    });
  };

  handleResetSearch = () => {
    this.setState({
      ...this.state,
      clearSearch: false,
      searchValue: '',
      activePage: 1,
    });
  };

  render() {
    const { currentTab, activePage, showRejectVacationRequestModal } = this.state;
    const { teamVacationsList, teamVacationsTotal, isFetching } = this.props;

    return (
      <Fragment>
        <Header title="Requests" hasRequestDay />
        <VacationRequestsList
          currentTab={currentTab}
          requestsList={teamVacationsList}
          requestsTotal={teamVacationsTotal}
          handleCurrentTabChange={this.handleCurrentTabChange}
          handleSearchData={this.handleSearchData}
          handleResetSearch={this.handleResetSearch}
          clearSearch={this.state.clearSearch}
          activePage={activePage}
          handlePageChange={this.handlePageChange}
          onDelete={this.onDelete}
          onDeny={this.onDeny}
          onApprove={this.onApprove}
          isFetching={isFetching}
        />
        <DeleteAcceptVacationRequestModal
          vacation={this.state.currentVacation}
          isOpen={this.state.showConfirmModal}
          toggle={this.toggleConfirmModal}
          header={this.state.confirmModalHeader}
          bodyText="Are you sure?"
          onConfirm={this.state.onSubmitConfirmModal}
        />
        <RejectVacationRequestModal
          vacation={this.state.currentVacation}
          showModal={showRejectVacationRequestModal}
          onSubmit={this.onConfirmDeny}
          toggleModal={this.toggleRejectVacationRequestModal}
          initialValues={{
            reason: '',
          }}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  teamVacationsList: store.teamVacations.list,
  teamVacationsPage: store.teamVacations.listPage,
  teamVacationsTotal: store.teamVacations.listTotal,
  isFetching: store.teamVacations.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTeamVacations: bindActionCreators(fetchTeamVacations, dispatch),
  fetchVacationForApproval: bindActionCreators(fetchVacationForApproval, dispatch),
  deleteRequest: bindActionCreators(deleteRequest, dispatch),
  requestAnswer: bindActionCreators(requestAnswer, dispatch),
  approveWorkOnHoliday: bindActionCreators(approveWorkOnHoliday, dispatch),
  rejectWorkOnHoliday: bindActionCreators(rejectWorkOnHoliday, dispatch),
  deleteWorkOnHoliday: bindActionCreators(deleteWorkOnHoliday, dispatch),
  fetchRequest: bindActionCreators(fetchRequest, dispatch),
  resetRequestFlow: bindActionCreators(resetRequestFlow, dispatch),
  resetRejectVacationRequestModalForm: bindActionCreators(
    resetRejectVacationRequestModalForm,
    dispatch,
  ),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(VacationRequests);
