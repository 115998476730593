import {
  FETCH_SIGNED_IN_USER_VACATIONS_START,
  FETCH_SIGNED_IN_USER_VACATIONS_SUCCESS,
  FETCH_SIGNED_IN_USER_VACATIONS_ERROR,
  FETCH_USER_AVAILABLE_VACATIONS_START,
  FETCH_USER_AVAILABLE_VACATIONS_SUCCESS,
  FETCH_USER_AVAILABLE_VACATIONS_ERROR,
  VACATION_REQUEST_START,
  VACATION_REQUEST_SUCCESS,
  VACATION_REQUEST_ERROR,
  CLEAN_REQUEST_ERRORS,
  TOGGLE_REQUEST_MODAL,
  ACTION_VACATION_REQUEST_START,
  ACTION_VACATION_REQUEST_SUCCESS,
  ACTION_VACATION_REQUEST_ERROR,
  EDIT_SIGNED_IN_USER_PENDING_VACATIONS,
  FETCH_VACATION_REQUEST_START,
  FETCH_VACATION_REQUEST_SUCCESS,
  FETCH_VACATION_REQUEST_ERROR,
  RESET_FETCH_REQUEST,
} from './types';
import { getData, postData, deleteData, baseURL, putData } from './api';
import { setGeneralAlertMessage, setUserVacationsHistoryPage } from '../actions/uiActions';
import { fetchSignedInUser } from './signedInUserActions';
import { fetchTeamVacations } from '../actions/teamVacationsActions';
import { fetchUserVacationHistory } from '../actions/userLogsActions';
import { PAGINATION_REQUESTS_PER_PAGE } from '../../constants/index';

export const fetchSignedInUserVacations = (
  requestType = 'pending',
  page = 1,
  count = PAGINATION_REQUESTS_PER_PAGE,
) => dispatch => {
  dispatch({ type: FETCH_SIGNED_IN_USER_VACATIONS_START });

  return getData(
    `${baseURL}api/vacations/me/${requestType}/page/${page}/count/${count}`,
    {},
    FETCH_SIGNED_IN_USER_VACATIONS_SUCCESS,
    FETCH_SIGNED_IN_USER_VACATIONS_ERROR,
    dispatch,
  );
};

export const fetchAvailableVacations = (userId, params = {}, callback) => dispatch => {
  dispatch({ type: FETCH_USER_AVAILABLE_VACATIONS_START });

  return getData(
    `${baseURL}api/user/profile/${userId}/requestStats`,
    params,
    FETCH_USER_AVAILABLE_VACATIONS_SUCCESS,
    FETCH_USER_AVAILABLE_VACATIONS_ERROR,
    dispatch,
    callback,
  );
};

export const fetchRequest = (requestId, successCallback) => dispatch => {
  dispatch({ type: FETCH_VACATION_REQUEST_START, payload: requestId });
  getData(
    `${baseURL}api/vacations/request/${requestId}/pending`,
    {},
    FETCH_VACATION_REQUEST_SUCCESS,
    FETCH_VACATION_REQUEST_ERROR,
    dispatch,
    data => {
      if (data.vacation.status === 'Pending') {
        successCallback(data.vacation);
      } else {
        dispatch(
          setGeneralAlertMessage(
            `${
              data.vacation.name
            }'s vacation has been already ${data.vacation.status.toLowerCase()} by ${
              data.vacation.approvedBy
            }`,
            'danger',
          ),
        );
        successCallback(null);
      }
    },
    error => {
      dispatch(setGeneralAlertMessage(error.response.data.message, 'danger'));
      successCallback(null);
    },
  );
};
export const resetRequestFlow = () => dispatch => {
  dispatch({ type: RESET_FETCH_REQUEST });
};

export const addRequest = requestData => dispatch => {
  dispatch({ type: VACATION_REQUEST_START });
  postData(
    `${baseURL}api/vacations/request`,
    requestData,
    VACATION_REQUEST_SUCCESS,
    VACATION_REQUEST_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch({ type: TOGGLE_REQUEST_MODAL });
      dispatch(setGeneralAlertMessage('Vacation request added', 'info'));
      dispatch(fetchSignedInUser()); // to refresh the dashboard card
    },
  );
};

export const requestAnswer = (requestId, action, requestBody, requestType, name) => dispatch => {
  dispatch({ type: ACTION_VACATION_REQUEST_START, payload: requestId });
  putData(
    `${baseURL}api/vacations/request/${requestId}/${action}`,
    requestBody,
    ACTION_VACATION_REQUEST_SUCCESS,
    ACTION_VACATION_REQUEST_ERROR,
    dispatch,
    () => {
      const message =
        action === 'reject'
          ? `${name}'s vacation request has been rejected`
          : `${name}'s vacation request has been accepted`;
      dispatch(fetchTeamVacations({ status: requestType }));
      dispatch(setGeneralAlertMessage(message, 'info'));
    },
  );
};

export const editRequest = (requestData, vacationId) => dispatch => {
  dispatch({ type: VACATION_REQUEST_START });
  putData(
    `${baseURL}api/vacations/request/${vacationId}`,
    requestData,
    VACATION_REQUEST_SUCCESS,
    VACATION_REQUEST_ERROR,
    dispatch,
    data => {
      dispatch({
        type: EDIT_SIGNED_IN_USER_PENDING_VACATIONS,
        payload: { data },
      });
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch({ type: TOGGLE_REQUEST_MODAL });
      dispatch(setGeneralAlertMessage(data.message, 'info'));
    },
  );
};

// todo: split up this action creator
// doesn't make sense to make it 'smart' based on page/request type
export const deleteRequest = (requestId, currentPage, requestType, userId) => dispatch => {
  dispatch({ type: ACTION_VACATION_REQUEST_START, payload: requestId });
  deleteData(
    `${baseURL}api/vacations/request/${requestId}`,
    ACTION_VACATION_REQUEST_SUCCESS,
    ACTION_VACATION_REQUEST_ERROR,
    dispatch,
    data => {
      switch (currentPage) {
        case 'admin':
          dispatch(
            fetchUserVacationHistory(userId, {
              page: 1,
              count: 5,
            }),
          );
          dispatch(setUserVacationsHistoryPage(0));
          dispatch(setGeneralAlertMessage('Request deleted', 'info'));
          break;
        case 'history':
          dispatch(fetchSignedInUserVacations(requestType));
          dispatch(fetchSignedInUser()); // to refresh the dashboard card
          dispatch(setGeneralAlertMessage('Request deleted', 'info'));
          break;
        case 'approvedRequests':
          dispatch(fetchTeamVacations({ status: requestType }));
          dispatch(setGeneralAlertMessage('Request deleted', 'info'));
          break;
        case 'dashboard':
          dispatch(setGeneralAlertMessage('You are working on this holiday', 'info'));
          break;
        default: {
        }
      }
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const cleanRequestErrors = () => dispatch => {
  dispatch({ type: CLEAN_REQUEST_ERRORS });
};
