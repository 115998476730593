import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { PAGINATION_REQUESTS_PER_PAGE } from '../../../../constants';
import VacationRequestsListItem from '../VacationRequestsListItem';
import CustomSearch from '../../../../components/ui/CustomSearch';
import CustomLoader from '../../../../components/ui/Loader';
import { firstDayOfAMonth, firstDayOfTheNextYear } from '../../../../utils/Date';

class VacationRequestsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVacation: {},
    };
  }
  firstDayOfTheNextYear = firstDayOfTheNextYear(new Date());

  hasDelete = request => {
    const { userRights } = this.props;
    const requestStartDate = new Date(request.startDate);

    return (
      (userRights.includes('hasAdministrativeRights') && request.status === 'Approved') ||
      (userRights.includes('hasApproverRights') &&
        request.status === 'Approved' &&
        requestStartDate >= firstDayOfAMonth(new Date()) &&
        requestStartDate < this.firstDayOfTheNextYear)
    );
  };

  hasApprove = request => {
    const { userRights } = this.props;
    return userRights.includes('hasApproverRights') && request.status === 'Pending';
  };

  hasDeny = request => {
    const { userRights } = this.props;
    return userRights.includes('hasApproverRights') && request.status === 'Pending';
  };

  render() {
    const {
      currentTab,
      requestsList,
      requestsTotal,
      handleCurrentTabChange,
      handleSearchData,
      handleResetSearch,
      clearSearch,
      activePage,
      handlePageChange,
      onDelete,
      onDeny,
      onApprove,
      isFetching,
      isFetchingRequest,
    } = this.props;

    const showApproverColumn = currentTab === 'pending' || currentTab === 'all';

    const pager = requestsList.length && requestsTotal > PAGINATION_REQUESTS_PER_PAGE && (
      <div className="away-pagination">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={PAGINATION_REQUESTS_PER_PAGE}
          totalItemsCount={requestsTotal}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="Prev"
          nextPageText="Next"
          itemClassNext="away-pagination-next"
          itemClassPrev="away-pagination-prev"
        />
      </div>
    );

    const requestsToShow =
      requestsList.length &&
      requestsList
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
        .map((request, i) => (
          <VacationRequestsListItem
            key={i}
            showApproverColumn={showApproverColumn}
            hasDelete={this.hasDelete(request)}
            hasApprove={this.hasApprove(request)}
            hasDeny={this.hasDeny(request)}
            requestData={request}
            onApprove={() => onApprove(request)}
            onDeny={() => onDeny(request)}
            onDelete={() => onDelete(request)}
          />
        ));

    return (
      <div className="away-tabs">
        <div className="card-shadow">
          <div className="at-header-wrapper">
            <div className="at-header">
              <button
                className={`at-link ${currentTab === 'all' && 'active'}`}
                onClick={() => handleCurrentTabChange('all')}
              >
                All
              </button>
              <button
                className={`at-link ${currentTab === 'pending' && 'active'}`}
                onClick={() => handleCurrentTabChange('pending')}
              >
                Pending
              </button>
              <button
                className={`at-link ${currentTab === 'approved' && 'active'}`}
                onClick={() => handleCurrentTabChange('approved')}
              >
                Approved
              </button>
            </div>
            <CustomSearch
              handleSearchData={handleSearchData}
              handleResetSearch={handleResetSearch}
              isDisabled={requestsList.length === 0}
              clearSearch={clearSearch}
            />
          </div>
          <div className="at-body at-body-big">
            {isFetching || isFetchingRequest ? (
              <CustomLoader />
            ) : requestsList && requestsList.length ? (
              <div className="away-list">
                <Row>
                  <Col md={{ size: 12 }}>
                    <div className="list-header">
                      <Row>
                        <Col md={{ size: 3 }} className="list-cell">
                          <span className="list-cell-text">Name</span>
                        </Col>
                        <Col md={{ size: 2 }} className="list-cell">
                          <span className="list-cell-text">Period</span>
                        </Col>
                        <Col md={{ size: 2 }} className="list-cell">
                          <span className="list-cell-text">Request Type</span>
                        </Col>
                        <Col md={{ size: 1 }} className="list-cell">
                          <span className="list-cell-text">Number Of Days</span>
                        </Col>
                        <Col md={{ size: 1 }} className="list-cell">
                          <span className="list-cell-text">Status</span>
                        </Col>
                        {showApproverColumn ? (
                          <Col md={{ size: 2 }} className="list-cell">
                            <span className="list-cell-text">Approver</span>
                          </Col>
                        ) : (
                          <Col md={{ size: 2 }} className="list-cell">
                            <span className="list-cell-text">Approved By</span>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
                {requestsToShow}
                {pager}
              </div>
            ) : (
              <h3 className="at-no-requests">
                There are no {currentTab === 'all' ? 'requests!' : `${currentTab} requests!`}
              </h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStoreToProps = store => ({
  showRequestModal: store.ui.showRequestModal,
  userRights: store.signedInUser.userRights,
  isFetchingRequest: store.signedInUserVacations.fetchRequest.isFetching,
});

export default connect(mapStoreToProps)(VacationRequestsList);
